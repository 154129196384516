<template>
  <div>
    <list
      ref="refProviderList"
      sort-by="proveedorTiId"
      refetch-records-name="proveedores"
      key-field="ProveedorTiId"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchProviders"
      :delete-method="deleteProvider"
      :sort-dir-desc="false"
    >
      <template #cell(ultimaModificacion)="data">
        <b-media v-if="stateTime.value" vertical-align="center">
          {{ data.item.ultimaModificacion | formatDateTime }}
        </b-media>
        <b-media v-else vertical-align="center">
          {{ data.item.ultimaModificacion | formatDate }}
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BMedia,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import tiService from '@/services/informationTecnology.service'
import List from '@/components/List.vue'

export default {
  components: {
    List,
    BMedia,
  },
  setup() {
    const refProviderList = ref(null)
    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateProviders')) {
      dateTimeFormater.push({ key: 'formatDateProviders', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateProviders'))

    let actions = ref([])

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
      const index = actions.value.findIndex(e => e.name === 'dateAndHour')
      actions.value[index].value = stateTime.value.value
    }

    const {
      fetchProviders,
      deleteProvider,
    } = tiService()
    const filters = ref([])
    const tableColumns = [
      {
        key: 'proveedorTiId',
        label: i18n.t('AppTI.columns.id'),
        sortable: true,
        sortKey: 'ProveedorTiId',
      },
      {
        key: 'nombre',
        label: i18n.t('AppTI.columns.name'),
        sortable: false,
      },
      {
        key: 'correo',
        label: i18n.t('AppTI.columns.email'),
        sortable: false,
      },
      {
        key: 'alias',
        label: i18n.t('AppTI.columns.nickname'),
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Details'),
        aclAction: 'read',
        aclResource: 'MesaAyuda',
        routeName: 'apps-provider-details',
        params: ['proveedorTiId'],
        icon: 'CodesandboxIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'ProveedorTi',
        params: ['proveedorTiId'],
        icon: 'TrashIcon',
      },
    ])
    actions = ref([
      {
        label: i18n.t('AppTI.actions.newProvider'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        routeName: 'apps-provider-new',
        icon: 'PlusCircleIcon',
      },
      {
        name: 'dateAndHour',
        aclAction: 'read',
        aclResource: 'AccionesMejora',
        icon: 'CalendarIcon',
        value: stateTime.value.value,
        click: changeStateTime,
      },
    ])

    return {
      refProviderList,
      fetchProviders,
      deleteProvider,
      filters,
      tableColumns,
      tableActions,
      actions,
      stateTime,
    }
  },
}

</script>
